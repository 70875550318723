<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col class="text-center text-h4 pa-5">
                Projects
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-lg-end text-center">
                <v-btn color="primary" lg="3" class="px-10" @click="$router.push('/addproject')">
                  Add new project
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class="text-center">
                <span class="text-h6">Investments Vs Investment Return</span>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="start_date"
                        label="Start date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="start_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="end_date"
                        label="End date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="end_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg='2' class="text-center">
                <v-btn color="primary" class="px-10" @click="getGraphData()">
                  Filter
                </v-btn>
              </v-col>
              <v-col cols="12" lg="10">
                <Chartjs :chartData="chartdata"/>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class="text-center">
                <span class="text-h6">All Projects</span>
              </v-col>
              <v-col cols="12" lg="6">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-data-table :headers="fields" :items="projects" :search="search" hide-default-footer mobile-breakpoint="0" :loading="full_loader" loading-text="Loading Data ...">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small color="primary" class="pa-0 ma-1" @click="$router.push(`projects/detail/${item.id}`)">
                      <v-icon small>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                    <v-btn small color="success" class="pa-0 ma-1" @click="$router.push(`/edit/project/${item.id}`)">
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn small color="error" class="pa-0 ma-1" @click="showDialog(item)">
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`top`]>
                    <v-dialog v-model="showdialog" max-width="500px">
                      <v-card class="pa-5">
                        <v-card-title class="text-h6">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" class="px-5 mr-2" @click="showdialog=false">Cancel</v-btn>
                          <v-btn color="primary" class="px-5" @click="deleteProject()">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col class="test-center mb-5">
                <v-pagination v-model="current_page" :length="total_pages" @next="currentPage()" @previous="currentPage()" @input="currentPage()"></v-pagination>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-overlay :value="graphLoader" color="white">
                  <v-progress-circular
                      indeterminate
                      size="64"
                      color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {get_projects, delete_project, get_project_graph_data} from "@/apis/project";
import Chartjs from '@components/base-components/charts.vue'
export default {
  components: {
    Chartjs
  },
  data() {
    return {
      start_date:'',
      end_date:'',
      chartdata: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
            label: 'Investment',
            data: [],
            backgroundColor: 'rgba(0,119,200,0.5)',
            borderColor: 'rgba(0,119,200,1)',
            borderWidth: 2
        },{
            label: 'Investment Return',
            data: [],
            backgroundColor: 'rgba(229,65,45,0.5)',
            borderColor: 'rgba(229,65,45,1)',
            borderWidth: 2
        }]
      },
      fields: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Ref Person",
          value: "ref_person",
        },
        {
          text: "Contact No.",
          value: "contact_number",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        }
      ],
      showdialog:false,
      search:'',
      projects: [],
      project_id: "",
      current_page: null,
      total_pages: 1,
      full_loader: false,
      graphLoader:false,
      errResp: '',
    }
  },
  mounted() {
    this.projectList();
  },
  methods: {
    getGraphData(){
      let data={
        start_date:this.start_date,
        end_date:this.end_date,
      }
      this.graphLoader=true;
      get_project_graph_data(data).then(resp=>{
        if(resp.status === 'Success'){
          this.setGraphData(resp.data)
          this.graphLoader=false;
        }else{
          this.errResp= resp.errors;
        }
      });
    },
    setGraphData(data){
      data.forEach(s=>{
        console.log(s);
      })
    },
    showDialog(item) {
      this.project_id = item.id;
      this.showdialog = true;
    },

    deleteProject() {
      this.full_loader = true;
      delete_project(this.project_id).then(
          resp => {
            this.showdialog = false;
            this.full_loader = false;
            if (resp.status == 'Success') {
              this.$toastr.s(resp.message);
              this.projectList();
            } else {
              this.error = resp.errors;
            }
          }
      )
    },

    projectList(page) {
      this.full_loader = true;
      get_projects(page).then(
          resp => {
            this.full_loader = false;
            this.projects = resp.data.data;
            this.current_page = resp.data.current_page;
            this.total_pages = resp.data.last_page
          }
      )
    },
    currentPage() {
      var p = Number(this.current_page)
      this.projectList({page: p})
    },
  }
}
</script>

<style>

</style>
