<script>
import { Bar } from 'vue-chartjs'
export default {
  name: "Chartjs",
  extends: Bar,
  props:{
    // chartData:[Object]
    data:[Array],
    chartData:[Object]
  },
  data: () => ({
    options: {
      maintainAspectRatio: false,
        //  scales: {
        //     xAxes:[{
        //         stacked: true
        //     }],
        //     yAxes: [{
        //         stacked: true,
        //         ticks: {
        //             beginAtZero: true
        //         }
        //     }]
        // }
    }
  }),
  watch:{
    chartData:{
      deep:true,
      handler(){
        this.renderChart(this.chartData, this.options)
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style>

</style>