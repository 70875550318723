import http from '@/util/http'
export async function create_project(data) {
    return (await http.post('project/create',data)).data;
}
export async function get_projects(params) {
    return (await http.get('project/list',{params})).data;
}
export async function delete_project(id) {
    return (await http.delete(`project/delete/${id}`)).data;
}
export async function edit_project(id) {
    return (await http.get(`project/edit/${id}`)).data;
}
export async function update_project(id,data) {
    return (await http.post(`project/update/${id}`,data)).data;
}
export async function get_project_details(data){
    return (await http.post('/project/detail',data)).data
}
export async function get_project_graph_data(data){
    return (await http.post('/project/graph',data)).data;
}
export async function get_all_projects(){
    return (await http.get('/project/all/projects')).data
}
export async function get_projact_transactions(data){
    return (await http.get(`/project/transaction/${data.id}`,{params:data.data})).data
}